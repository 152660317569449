<template>
  <div id="login-section" :class="pageKey === 'market' || pageKey === 'kaorder' ? 'no-result' : 'no-result card main'" :style="border === true ? 'border:none;box-shadow:none;padding:0px;margin:0px;' : ''"> 
    <span class="link" @click="this.$emit('login')">{{ contentData.title }}</span><br><br>
    <span style="font-size: 15px;"><label class="link" @click="this.$emit('login')">{{ contentData.obj }}</label>{{ contentData.desc }}</span>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  props: {
    border:Boolean,
    pageKey:String
  },
  setup(props) {
    const contents = new Map([
      ['dashboard', { title: '전체 주문 내역', obj: '주문자', desc: ' 정보를 선택해 주시면 조회됩니다!' }],
      ['list', { title: '상세 주문 내역', obj: '주문자', desc: ' 정보를 선택해 주시면 조회됩니다!'}],
      ['kaorder', { title: '전체 주문 내역', obj: '주문자', desc: ' 정보를 선택해 주시면 조회됩니다!'}],
      ['market', { title: '상품 등록', obj: '상품 등록자', desc: ' 정보가 필요합니다!'}],
      ['order', { title: '대행신청', obj: '대행 신청자', desc: ' 정보가 필요합니다!'}],
      ['qna', { title: '문의하기', obj: '문의자', desc: ' 정보가 필요합니다!'}],
      ['write', { title: '글쓰기', obj: '작성자', desc: ' 정보가 필요합니다!'}],
      ['shop', { title: '해외 중고 상품 검색', obj: '가입 / 로그인', desc: '을 하셔야 서비스를 이용하실 수 있습니다'}],
      ['contract', { title: '가입 / 로그인', obj: '가입 / 로그인', desc: '을 하셔야 서비스를 이용하실 수 있습니다' }]
    ])
    const defaultContent = {
      title: '가입 / 로그인',
      obj: '가입 / 로그인',
      desc: '을 하셔야 서비스를 이용하실 수 있습니다'
    }

    const contentData = computed(() => {
      return contents.get(props.pageKey) || defaultContent
    })
    return {
      contentData
    }
  }
}
</script>
<style scoped>
  #login-section {
    font-size:20px;
    font-weight:bold;
  }
</style>
