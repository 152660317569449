<template>
  <div>
    <table class="dynamic-table">
      <tr style="height:100px;">
        <td style="width:100%;">
          <table class="order_sub_table" style="table-layout:fixed;">
            <tbody style="text-align: left;">
              <tr style="height:30px;">
                <td class="container_sub_style">
                  <div class="div_center">
                    <div style="min-width:100px;" v-tooltip="'유형에 따라 수수료가 다릅니다!'">대행유형*</div>
                    <div style="width:100%;">
                      <select class="normalInput" ref="type" id="type" :value='orderType' v-if="isHelper === true" disabled>
                        <option value = "" selected>선택</option>
                        <option value = "구매대행">구매대행</option>
                        <option value = "배송대행">배송대행</option>
                      </select>
                      <select class="normalInput" ref="type" id="type" :value='orderType' v-else>
                        <option value = "" selected>선택</option>
                        <option value = "구매대행">구매대행</option>
                        <option value = "배송대행">배송대행</option>
                      </select>
                    </div>
                  </div>
                </td>
                <td class="container_sub_style">
                  <div class="div_center">
                    <div style="min-width:100px;" v-tooltip="'구매한 상품의 출고지 혹은 화폐 단위 등으로 판단하셔야 됩니다'">대행국가*</div>
                    <div style="width:100%;">
                      <select class="normalInput" ref="country" id="country" :value='country' @change="click({type:'change'})">
                        <option value = "" selected>선택</option>
                        <option value = "홍콩">홍콩(HKD)</option>
                        <option value = "프랑스">프랑스(EUR)</option>
                        <option value = "영국">영국(GBP)</option>
                        <option value = "스페인">스페인(EUR)</option>
                        <option value = "독일">독일(EURO)</option>
                        <option value = "이탈리아">이탈리아(EUR)</option>
                        <option value = "일본">일본(JPY)</option>
                        <option value = "중국">중국(CNY)</option>
                        <option value = "미국">미국(USD)</option>
                        <option value = "싱가포르">싱가포르(SGD)</option>
                        <option value = "네덜란드">네덜란드(EUR)</option>
                        <option value = "호주">호주(AUD)</option>
                        <option value = "뉴질랜드">뉴질랜드(NZD)</option>
                        <option value = "오스트리아">오스트리아(EUR)</option>
                        <option value = "벨기에">벨기에(EUR)</option>
                        <option value = "대만" >대만(TWD)</option>
                      </select>
                    </div>
                  </div>
                </td>
              </tr>
              <tr style="height:30px;">
                <td class="container_sub_style">
                  <div class="div_center">
                    <div style="min-width:100px;" v-tooltip="'개인통관고유부호를 발급받을 때 사용한 이름이어야 합니다.'">이름*</div>
                    <div style="width:100%;">
                      <input class="normalInput" ref="name" type="text" :value="orderName" placeholder="받는 분의 한글 성함">
                    </div>
                  </div>
                </td>
                <td class="container_sub_style">
                  <div class="div_center">
                    <div style="min-width:100px;" v-tooltip="'정확하지 않으면 한국 세관에서 바로 통관이 되지 않습니다.'">개인통관<br>고유부호*</div>
                    <div style="width:100%;">
                      <input class="normalInput" ref="clearance" type="text" :value="clearance" placeholder="받는 분의 성함으로 신청한 통관부호">
                    </div>
                  </div>
                </td>
              </tr>
              <tr style="height:30px;">
                <td class="container_sub_style">
                  <div class="div_center">
                    <div style="min-width:100px;" v-tooltip="'문제 발생 시 연락을 받으실 수 있는 연락처를 적어주세요.'">폰번호*</div>
                    <div style="width:100%;">
                      <input class="normalInput" ref="phone" type="tel" placeholder="숫자만 입력" @blur="validate('phone', $refs.phone)" :value="phone">
                    </div>
                  </div>
                </td>
                <td class="container_sub_style">
                  <div class="div_center">
                    <div style="min-width:100px;" v-tooltip="'진행 상황은 이메일로 전달해 드립니다.'">이메일*</div>
                    <div style="width:100%;">
                      <input class="normalInput" ref="mail" type="email" placeholder="진행 상황을 받을 이메일 주소" @blur="validate('email', $refs.mail)" :value="mail">
                    </div>
                  </div>
                </td>
              </tr>
              <tr style="height:30px;">
                <td class="container_sub_style">
                  <div class="div_center">
                    <div style="min-width:100px;" v-tooltip="'도로명 주소와 상세 주소를 잘 구분해서 적어주세요.'">도로명<br>주소*</div>
                    <div style="width:100%;">
                      <input class="normalInput" ref="addr_zip" type="text" :value="addrZip" placeholder="우편번호" readonly @focusin="click({type:'showModal'})" style="max-width:25%;margin-right:5px;">
                      <input class="normalInput" ref="addr_road" type="text" :value="addrRoad" placeholder="한글 도로명 주소" readonly @focusin="click({type:'showModal'})" style="width:70%;">
                    </div>
                  </div>
                </td>
                <td class="container_sub_style">
                  <div class="div_center">
                    <div style="min-width:100px;" v-tooltip="'도로명 주소와 상세 주소를 잘 구분해서 적어주세요.'">상세주소</div>
                    <div style="width:100%;">
                      <input class="normalInput" ref="addr_detail" type="text" :value="addrDetail" placeholder="한글 상세 주소">
                    </div>
                  </div>
                </td>
              </tr> 
            </tbody>
          </table>
        </td>
      </tr>
    </table>
    <br>
    <div>
      <p class="no-result">한국이 아닌 다른 국가로 배송을 원하시면 한국 주소지로 대행신청을 하신 후에 <a href="https://geek9.kr/qna" target="_target">문의게시판</a>에 해외배송 주소지를 남겨주시기 바랍니다.</p>
    </div>
  </div>
  <Modal v-model='isShow' style="z-index:99999999999;">
    <div id="modal_main" class="modal">
      <div class="div_title" style="margin-top:-20px;"><span style="color: #ff7f50;">주소검색</span> 
      </div>
      <br>
      <table id="modal_table" class="table" style="width:100%;">
        <tr>
          <td>도로명주소</td>
          <td>
            <input class="normalInput" v-model="modalAddrZip" type="number" style="max-width:12%" placeholder="우편번호">
            <input class="normalInput" v-model="modalAddrRoad" type="text" style="max-width:55%;margin-left:5px;margin-right:5px;"  placeholder="도로명주소" v-on:keyup.enter="search({addrRoad:modalAddrRoad})" >
            <input class="normalInput" type="button" style="width:10%;min-width:15%;font-size:10px;" @click="search({addrRoad:modalAddrRoad})" value="검색">
          </td>
        </tr>
        <tr>
          <td>상세주소</td>
          <td>
            <input class="normalInput" v-model='modalAddrDetail' type="text" style="max-width:86%"  placeholder="상세주소">
          </td>
        </tr>
        <tr v-if="addrList.length === 0"  >
          <td colspan="2" style="background: transparent;">
            조회 내역이 없습니다.
          </td>
        </tr>
        <tr v-else v-for="(addr, index) in addrList" :key="index">
          <!-- <td colspan="2" style="background: transparent;">({{ addr.addrZip }}) {{ addr.addrRoad }}</td> -->
          <td v-on:click="click({type:'addrSelect', idx:index})" style="background: transparent" >{{  addr.addrZip }}</td>
          <td v-on:click="click({type:'addrSelect', idx:index})">{{ addr.addrRoad }}</td>
        </tr>
      </table>
      <div class="div_center">
        <Button @click="click({type:'confirm'})" raised label="확인" />
        <Button @click="closeModal()" raised label="취소" />
      </div>
    </div>
  </Modal>    
</template>

<script>
import {ref} from 'vue'
export default {
  setup() {
    const isShow = ref(false)
    const showModal = async () => {
      isShow.value = true;
    }
    const closeModal = async () => {
      isShow.value = false
    }
    return {
      isShow, showModal, closeModal
    }
  },  
  data() { 
    return { 
      modalAddrRoad:'',
      modalAddrDetail:'',
      modalAddrZip:'',
      addrList:[],
    }
  },
  props: {
    orderName: String,
    clearance: String,
    addrZip: String,
    addrRoad: String,
    addrDetail: String,
    phone: String,
    mail: String,
    country: String,
    orderType: String,
    isHelper: Boolean
  },
  emits: ['update:addrRoad','update:addrDetail','update:addrZip','update:orderName','update:clearance','update:phone', 'update:mail','update:country','update:orderType'],
  methods: {
    async search(param) {
      const addrRoad = param.addrRoad
      if(addrRoad === undefined || addrRoad === undefined || addrRoad === '') {
        alert("주소를 입력해주세요.")
      } else {
        await this.api.axiosGql('ADDRESS', {type:'ADDRESS', addrRoad: addrRoad, isKor:1})
        .then( async (result) => {
          const json = result.data
          if(json === undefined || json.length === 0) alert('잘못된 주소입니다.')
          else {
            this.addrList = json
            this.modalAddrRoad = ''
          }
        })
      }
    },
    click(param) {
      const type = param.type
      if(type === 'addrSelect') {
        const json = this.addrList[param.idx]
        this.modalAddrZip = json.addrZip
        this.modalAddrRoad = json.addrRoad
      } else if(type === 'confirm') {
        if(this.modalAddrRoad === '' || this.modalAddrZip === '') alert('주소를 선택해주세요')
        else {
          this.closeModal()
          this.$emit('update:addrZip', this.modalAddrZip)
          this.$emit('update:addrRoad', this.modalAddrRoad)
          this.$emit('update:addrDetail', this.modalAddrDetail)
        }
      } else if(type === 'showModal') {
        this.$emit('update:clearance', this.$refs.clearance.value)
        this.$emit('update:phone', this.$refs.phone.value)
        this.$emit('update:mail', this.$refs.mail.value)
        this.$emit('update:country', this.$refs.country.value)
        this.$emit('update:orderType', this.$refs.type.value)
        this.$emit('update:orderName', this.$refs.name.value)
        this.$emit('update:addrDetail', this.$refs.addr_detail.value)
        this.modalAddrRoad = '',
        this.modalAddrDetail = '',
        this.modalAddrZip = '',
        this.addrList = []
        this.showModal()
      }
    },
    validate(type, target) {
      this.api.validate(type, target)
    },
    // getAddrZip() {
    //   console.log('focus-in')
      // const addrRoad = this.$refs.addr_road.value
      // if(addrRoad.length > 2) {
      //   this.api.axiosGql("GET_ADDR_ZIP", {type:"GET_ADDR_ZIP", addrRoad:addrRoad})
      //   .then( (res) => {
      //     const json = res.data.result
      //     let addrZip = json.addrZip 
      //     if(addrZip !== '') {
      //       if(addrZip.length > 5) addrZip = addrZip.substring(0,5)
      //       const newAddrZip = Number(prompt('다음의 우편번호가 맞나요?', addrZip ))
      //       let finalAddrZip
      //       if(newAddrZip === 0) finalAddrZip = ''
      //       else finalAddrZip = newAddrZip
      //       this.$refs.addr_zip.value = finalAddrZip
      //     }
      //     let addrRoad = json.addrRoad
      //     if(addrRoad !== '') {
      //       const newAddrRoad = prompt('다음의 주소가 맞나요?', addrRoad )
      //       if(newAddrRoad !== null) {
      //         this.$refs.addr_road.value = newAddrRoad
      //       }
      //     }
      //   }).catch( (err)=> {
      //     console.log(err)
      //   })
      // }
    // }
  }
}
</script>

<style scoped>
select {
  text-align: center;
  width:100%;
}

</style>
